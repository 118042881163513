'use client';

import { isEmpty } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import aboutWishImage from 'public/image/about/about_wish.webp';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import HomeHeroVideo from './components/HomeHeroVideo';
import Button from '@/components/UI/Button';
import { EGa4Btn } from '@/constants/ga4';
import { homeInstructors } from '@/constants/homeInstructors';
import { boxElevation } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';
import { sentGA4BtnEvent } from '@/utils/ga4';
import imageLoader from '@/utils/loader';

const Container = styled.section`
	align-items: center;
	display: flex;
	flex-direction: column-reverse;
	gap: 0;
	justify-content: space-between;
	padding: 0 16px 14px 16px;
	position: relative;
	@media ${devices.laptop} {
		flex-direction: column;
		gap: 46px;
		padding: 0 0 10px 0;
	}
	&:before {
		background-image: url('/image/dot-black.svg');
		background-position: center;
		background-repeat: repeat;
		content: '';
		display: none;
		height: 46px;
		left: 24px;
		position: absolute;
		top: 576px;
		width: 274px;
		@media ${devices.laptop} {
			display: block;
		}
	}
	&:after {
		background-image: url('/image/dot-black.svg');
		background-position: center;
		background-repeat: repeat;
		content: '';
		display: none;
		height: 46px;
		position: absolute;
		right: 24px;
		top: 853px;
		width: 274px;
		@media ${devices.laptop} {
			display: block;
		}
	}
`;

const InfoSection = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	max-width: 1440px;
	padding-top: 0;
	position: relative;
	width: 100%;
	@media ${devices.laptop} {
		padding-top: 109px;
	}
	&::before {
		background-image: url('/icon/hero-gear-icon.svg');
		content: '';
		display: none;
		height: 32px;
		position: absolute;
		right: 350px;
		top: 166px;
		width: 34px;
		@media ${devices.largeLaptop} {
			display: block;
		}
	}
	&::after {
		background-image: url('/icon/hero-stairs-icon.svg');
		content: '';
		display: none;
		height: 30px;
		left: 375px;
		position: absolute;
		top: 80px;
		width: 27px;
		@media ${devices.largeLaptop} {
			display: block;
		}
	}
`;

const InfoText = styled.div`
	margin-bottom: 24px;
	text-align: left;
	@media ${devices.mobile} {
		text-align: center;
		margin-bottom: 64px;
	}
`;

const HeroImage = styled.div`
	margin-bottom: 24px;
	margin-top: 8px;
	width: 100%;
	z-index: 1;
	@media ${devices.laptop} {
		max-height: 432px;
		max-width: 767px;
		margin-top: 0;
		margin-bottom: 32px;
		width: 767px;
	}
`;

const StyledImage = styled(Image)`
	height: 100%;
	width: 100%;
	${boxElevation(4, color.blackColor)}
	@media ${devices.laptop} {
		${boxElevation(8, color.blackColor)}
	}
`;

const TitleContainer = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;
	@media ${devices.mobile} {
		align-items: center;
		margin-bottom: 55px;
	}
`;

const Title = styled.h1`
	color: ${color.primaryColor};
	font-size: 26px;
	font-weight: 600;
	margin: 0;
	padding: 0;
	@media ${devices.mobile} {
		font-size: 38px;
	}
`;

const SubTitle = styled.h2`
	color: ${color.blackColor};
	font-size: 26px;
	font-weight: 600;
	margin: 0;
	padding: 0;
	@media ${devices.mobile} {
		font-size: 38px;
	}
`;

const SubTitleHide = styled.h2`
	color: ${color.bgColor};
	font-size: 1px;
`;

const Description = styled.p`
	color: ${color.blackColor};
	font-size: 14px;
	line-height: 1.71;
	margin: 0;
	max-width: 547px;
	padding: 0;
`;

const Instructor = styled.div`
	display: none;
	height: 126px;
	max-width: 152px;
	position: absolute;
	@media ${devices.laptop} {
		display: block;
	}
	&:nth-child(1) {
		left: 0;
		top: 48px;
	}
	&:nth-child(2) {
		left: 105px;
		top: 204px;
	}
	&:nth-child(3) {
		left: 24px;
		top: 361px;
	}
	&:nth-child(4) {
		right: 19px;
		top: 48px;
	}
	&:nth-child(5) {
		right: 98px;
		top: 204px;
	}
	&:nth-child(6) {
		right: 32px;
		top: 361px;
	}
`;

const InstructorImage = styled(Image)`
	height: 100%;
	transform-style: preserve-3d;
	width: 100%;

	&.animate {
		animation: fadeIn 2s forwards;
	}

	${Instructor}:nth-child(1) & {
		animation-delay: 0.5s;
	}

	${Instructor}:nth-child(2) & {
		animation-delay: 0.7s;
	}

	${Instructor}:nth-child(3) & {
		animation-delay: 0.9s;
	}

	${Instructor}:nth-child(4) & {
		animation-delay: 0.6s;
	}

	${Instructor}:nth-child(5) & {
		animation-delay: 0.8s;
	}

	${Instructor}:nth-child(6) & {
		animation-delay: 1s;
	}

	@keyframes fadeIn {
		0% {
			opacity: 0;
			transform: translate3d(0, 0, 0) scale3d(0.5, 0.5, 1);
		}
		100% {
			opacity: 1;
			transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
		}
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	@media ${devices.mobile} {
		justify-content: center;
	}
`;
interface HomeHeroProps {
	homeHeroVideoUrl: string;
}
const HomeHero = ({ homeHeroVideoUrl }: HomeHeroProps) => {
	const pathname = usePathname();
	const [shouldAnimate, setShouldAnimate] = useState(false);

	useEffect(() => {
		requestAnimationFrame(() => {
			setShouldAnimate(true);
		});
	}, []);

	const handleClick = () => {
		// GA4 Event
		sentGA4BtnEvent(EGa4Btn.HOME_HERO, pathname);
	};

	const renderInstructors = () => {
		return homeInstructors.map(homeInstructor => {
			return (
				<Instructor key={homeInstructor.link}>
					<Link href={homeInstructor.link}>
						<InstructorImage
							className={shouldAnimate ? 'animate' : ''}
							src={homeInstructor.imageSrc}
							alt="Instructor"
							loader={imageLoader}
							width={0}
							height={0}
							unoptimized
							style={{ opacity: 0 }}
						/>
					</Link>
				</Instructor>
			);
		});
	};

	return (
		<Container>
			<InfoSection>
				<InfoText>
					<TitleContainer>
						<Title>匠人学院</Title>
						<SubTitle>学IT 拿Offer 找匠人</SubTitle>
						<SubTitleHide>澳洲IT 澳洲找工作 澳洲实习 求职</SubTitleHide>
					</TitleContainer>
					<Description>
						学IT, 找匠人, 拿Offer. 深耕澳洲IT求职市场7年, 超过600位老师,
						5000+名学员信任, 超过千家公司信任学员. 面向在校生, 毕业生, 新移民,
						转码等同学, 通过独创培训体系, 面向不断更新的企业需求, 结合实习, Career
						Coaching帮助，轻松步入澳洲职场。无需繁琐流程，让澳洲求职变得轻而易举！
					</Description>
				</InfoText>
				<ButtonContainer>
					<Button href="/bootcamp" onClick={handleClick}>
						选择你的课程
					</Button>
				</ButtonContainer>
				<div>{renderInstructors()}</div>
			</InfoSection>
			{!isEmpty(homeHeroVideoUrl) ? (
				<HomeHeroVideo videoUrl={homeHeroVideoUrl} />
			) : (
				<HeroImage>
					<StyledImage
						src={aboutWishImage}
						alt="Hero Image"
						loader={imageLoader}
						width={0}
						height={0}
						unoptimized
						priority
						placeholder="blur"
					/>
				</HeroImage>
			)}
		</Container>
	);
};

export default HomeHero;
